<template>
  <div id="newsletter">
    <div id=spacer height=50px></div>
    <iframe class="mj-w-res-iframe" frameborder="0" scrolling="no"
      marginheight="0" marginwidth="0" src="https://app.mailjet.com/widget/iframe/2UMs/iut"
      width="100%" height="400px" style="margin-top: 100px"></iframe>
  </div>
</template>

<script>
export default {

  data() {
    return {

    };
  },
  mounted() {
    // let mailjetscript = document.createElement('script')
    // mailjetscript.setAttribute('src', 'https://app.mailjet.com/statics/js/iframeResizer.min.js')
    // document.appendChild(mailjetscript)
  },
};
</script>

<style lang="scss" scoped>
#newsletter {
  display: grid;
  grid-template-rows: 100px 500px 1fr;
}
iframe {
  grid-template-areas: 3/1/4/2;
  display: block;
  min-height: 200px;
}
