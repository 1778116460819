<template>
  <div>
    <v-card class="mx-auto my-12" max-width="600">
      <v-card-title>
        <v-icon large color="red" class="pr-4">mdi-alert-decagram</v-icon>
        WARNING
      </v-card-title>
      <v-card-text>
        <vue-markdown>{{ warning }}</vue-markdown>
      </v-card-text>
    </v-card>
    <v-container>
      <v-row>
        <v-col
          class="text-lg-h5 text-md-h6 font-weight-light primary--text text--darken-2"
        >
          HydroE App on Android Mobile Devices
        </v-col>
      </v-row>
      <v-row justify="start">
        <v-col v-for="(screen, index) in screens" :key="index" sm="12" md="6" xl="4">
          <v-card min-height="400" class="primary--text text--darken-4">
            <v-card-title>{{ screen.title }}</v-card-title>
            <div class="mx-4">
              <v-img
                class="mr-5"
                :src="require(`@/images/screen/${screen.img}.jpg`)"
                max-width="150"
                max-height="300"
                style="float: left"
              ></v-img>
              <vue-markdown class="mr-2">{{ screen.text }}</vue-markdown>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import VueMarkdown from '@adapttive/vue-markdown';

export default {
  components: {
    VueMarkdown,
  },
  data: () => ({
    warning: `+ Nutrient flow systems should not be used indoors.
+ Do not touch the electrode surfaces as this can lead to contamination and inaccurate readings.
+ Read all instructions before setting up and using.`,
    screens: [
      {
        title: 'Home Screen',
        img: 'shome',
        text:
          'Screen displayed after starting the HydroE app. Top of the page is our mascot, Leef.<br><br> Bluetooth '
          + 'has to be enabled and permission must be granted to access location. If location permission is not granted than Bluetooth will not work. '
          + 'Pressing the Bluetooth button at the bottom will start a scan for any nearby HydroE devices. ',
      },
      {
        title: 'Choose HydroE Device',
        img: 'sbluetooth',
        text:
          'Choose one of the local HydroE controllers and press the connect button to connect to the device. ',
      },
      {
        title: 'Sensor Screen',
        img: 'ssensor',
        text:
          'After a few seconds the device should connect and the current sensor readings should be displayed. '
          + 'The displayed sensor readings are:\n1. Conductivity of the solution in the tank\n1. Air Temperature.'
          + 'This is only an estimate since the temperature sensor is on the HydroE controller and can be affected by '
          + 'heating from the electronics and the sun.\n1. Water Temperature. This measures the temperature of '
          + 'water in the tank.\n1. Sunlight. This gives an estimate of the light intensity at the surface of the HydroE controller. ',
      },
      {
        title: 'Sensor Screen II',
        img: 'soff',
        text:
          'Below the sensors are buttons to turn off the pump and the controller (button turns red when off). Be aware that not turning '
          + 'the pump back on again can be **fatal** for the plants in your nutrient-flow set up.<br><br> '
          + 'If the device is connected to wifi this will be shown near the bottom of the display as a wifi address. '
          + 'Below this is the device name.<br><br>To disconnect the android app from the device, press the disconnect button. ',
      },
      {
        title: 'Settings',
        img: 'ssettings',
        text:
          'Pressing Settings in the bottom menu will display the settings page.\n1. **Conductivity** sets '
          + 'the conductivity of the nutrient solution.\n1. **Tank Size** is an estimate of the tank size. '
          + 'This allows the controller to roughly determine the amount of nutrients to add.\n1. **Nutrient Constant** is '
          + 'a measure of the nutrients in the concentrated nutrients to be added to the tank. It can be '
          + 'left at the value 0.8.\n 1. **Peristaltic Flow** measures the flow of the peristaltic pumps. It can be left at '
          + 'value 4.0.\n 1. **Valve Flow** is an estimate of the flow of fresh water through the valve to fill the tank. This value '
          + 'is not important in the operation of the HydroE controller, but is used to give an estimate of water usage. '
          + '\n1. **Pump on/off Time** can be used to turn the pump on and off for a fixed amount of time. This may be useful in a '
          + 'flood and drain system.\n1. **Nutrient Interval** determines how often the nutrients are added to the tank. For large '
          + 'tanks it may take sometime for the nutrients to mix in the tank. If not enough time is left then the controller may add '
          + 'too much nutrients to the tank. For large tanks a pre-mixer may be required. ',
      },
      {
        title: 'Preferences',
        img: 'spreferences',
        text: 'Pressing the Preferences in the bottom menu will display the preferences page.\n1. **Setup HydroE Wifi** will '
              + 'start a scan for local wifi hotspots.\n1. **Set HydroE Units** will set the units to display on the app. '
              + '\n1. **Delete HydroE Data** will delete all recorded data on the HydroE controller.\n1. **Change HydroE Name** will change '
              + 'the name of the controller.',
      },
      {
        title: 'Wifi',
        img: 'swifi',
        text: 'On choosing **Setup HydroE Wifi** a scan for local wifi hotspots will be started. Once the scan is complete a hotspot '
              + 'can be chosen from the dropdown menu and a password entered. The HydroE controller should now connect to the hotspot. The ip '
              + 'address for the controller will appear on the home page of the app. Using a web browser it is possible to connect to the '
              + 'HydroE controller by selecting the HYDROE tab in the navigation bar above. '
              + 'The HydroE device will not start saving data until it is connected to the internet, since it requires this connection to '
              + 'set the device time.',
      },
      {
        title: 'Units',
        img: 'sunits',
        text: 'Sets the display units for conductivity, temperature and volume on the app.',
      },
    ],
  }),
  setup() {},
};
</script>
