<template>
  <v-app>
    <navbar />
    <v-container class="mb-1000">
      <v-main class="mx-40">
        <router-view></router-view>
      </v-main>
    </v-container>
  </v-app>
</template>

<script>
import Navbar from '@/components/Navbar.vue';

export default {
  name: 'App',
  components: { Navbar },

  data: () => ({
    //
  }),
};
</script>
