<template>
  <div>
    <v-container class="mt-10">
      <v-row>
        <v-col v-for="(dis, index) in discover" :key="index" sm="12" md="6">
          <v-card :color="dis.color" dark>
            <v-img
              :src="require(`@/images/${dis.img}.jpg`)"
              max-height="150"
            ></v-img>
            <span class="d-flex justify-space-between">
              <div>
                <v-card-title
                  class="font-weight-light primary--text text--darken-3"
                  v-text="dis.title"
                ></v-card-title>
                <v-card-text class="primary--text text--darken-4">
                  <vue-markdown>{{ dis.text}}</vue-markdown>
                </v-card-text>
              </div>
            </span>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import VueMarkdown from '@adapttive/vue-markdown';

export default {
  components: {
    VueMarkdown,
  },
  data: () => ({
    discover: [
      {
        color: '#e1eaea',
        img: 'stomatoes',
        title: 'Plant Requirements',
        text:
          ':herb: Plants require <font size="3" class="font-weight-medium">nutrients</font>, warmth, and light to grow.<br/><br/> '
          + ':sunny: The air supplies carbon dioxide the source of carbon within a plant. Carbon is essential for all the living processes '
          + 'occuring within the plants cells. The air also supplies oxygen which is required for respiration within the plants. '
          + 'Water is mainly required to support the chemical reactions occuring within the plant cell, but also supplies hydrogen ions.<br/> '
          + ':leaves: The soil supplies all the other nutrients that plants require, although some of these '
          + 'especially nitrogen can come from the air as well. The nutrients that plants acquire '
          + 'from soil can be divided into two categories. The macronutrients are **calcium**, **potassium**, '
          + '**nitrogen**, **phosphorous**, **magnesium** and **sulfur** and the micronutrients are **iron**, **boron**, '
          + '**manganese**, **chlorine**, **zinc**, **copper**, **molybdenum** and **nickel**. This may seem a lot to control '
          + 'but ElasticGreen\'s <font size="4" class="font-weight-thin">boostE</font> nutrient solutions are specifically developed to supply these '
          + 'elements in concentrations that allow a broad range of plants to grow. ',
      },
      {
        color: '#e1e1ea',
        img: 'splant_mosaic',
        title: 'Hydroponics',
        text:
          ':tomato: Hydroponics is a system of growing plants by replacing the soil with a mineral '
          + 'nutrient solutions. Hydroponics has the advantage over soil in that it easy to supply '
          + 'adequate water and nutrients to the plant. Plants can be grown in a much smaller area '
          + 'as long as there is adequate light. The result is high yields of crops in a small '
          + 'growing area. Environmentally, the water requirements are dramatically reduced compared '
          + 'to soil grown crops and the nutritional management of the crop is easier, without '
          + 'leaching of nutrients into the local acquifer. <br/><br/>There are various hydroponic techniques '
          + 'but for the home use we recommend the nutrient-flow as it is easy to set up, especially '
          + 'with our <font size="4" class="font-weight-thin">babylonE</font> sleeves. ',
      },
    ],
  }),
};
</script>
