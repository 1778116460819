<template>
  <div class="home primary--text">
    <v-container>
      <v-row>
        <v-col class="">
          <!-- <v-img
            src="@/assets/background.svg"
            max-width="1000"
            max-height="500"
            class="d-flex align-end"
          > -->
            <h2 class="font-weight-light mb-12 primary--text text--darken-3">
              Your Food Grown by You
            </h2>
          <!-- </v-img> -->
        </v-col>
      </v-row>
      <v-divider class="ma-8"></v-divider>
      <v-row>
        <v-col>
          <div class="text-h5 font-weight-thin" cols="12">
            What ElasticGreen offers
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col v-for="(benefit, index) in benefits" :key="index" sm="12" md="6">
          <v-card :color="benefit.color" dark>
            <span class="d-flex justify-space-between">
              <div>
                <v-card-title
                  class="font-weight-light primary--text text--darken-3"
                  v-text="benefit.title"
                ></v-card-title>
                <v-card-subtitle
                  class="primary--text text--darken-2"
                  v-text="benefit.text"
                ></v-card-subtitle>
              </div>
              <v-img
                :src="require(`@/undraw/${benefit.img}.svg`)"
                max-height="250"
                max-width="200"
                min-width="150"
              ></v-img>
            </span>
          </v-card>
        </v-col>
      </v-row>
      <v-divider class="ma-8"></v-divider>
      <v-row>
        <v-col>
          <div class="text-h5 font-weight-thin" cols="12">Our innovations</div>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          v-for="(innov, index) in innovations"
          :key="index"
          sm="12"
          md="6"
        >
          <v-card :color="innov.color" dark>
            <span class="d-flex justify-space-between">
              <div>
                <v-card-title
                  class="font-weight-light primary--text text--darken-3"
                  v-text="innov.title"
                ></v-card-title>
                <v-card-subtitle
                  class="primary--text text--darken-2"
                  v-text="innov.text"
                ></v-card-subtitle>
              </div>
              <v-img
                :src="require(`@/undraw/${innov.img}.svg`)"
                max-height="150"
                max-width="180"
                min-width="150"
              ></v-img>
            </span>
          </v-card>
        </v-col>
      </v-row>
      <v-divider class="ma-8"></v-divider>
    </v-container>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  // name: 'App',
  components: {},
  data: () => ({
    introduction:
      'Due to many innovative features ElasticGreen is able to bring hydroponics to your home '
       + 'at an unprecendented low coast with high ease of use. Now you can enjoy your '
       + 'own tasty, nutritious food',
    benefits: [
      {
        color: '#f1fafa',
        img: 'eating_together',
        title: 'The Produce',
        text:
          'Handled, picked and eaten by you. Guaranteed to be fresh. Packed with nutrients and flavor.',
      },
      {
        color: '#fafaf1',
        img: 'growing',
        title: 'High yield',
        text:
          'Vegetables, herbs and fruits have much higher yields and extended growing season, within a small footprint.',
      },
      {
        color: '#faf1fa',
        img: 'fingerprint',
        title: 'Variety',
        text:
          'The range and types of vegetables and fruits that you can grow is much greater than that found on supermarket shelves.',
      },
      {
        color: '#fafafa',
        img: 'credit_card',
        title: 'Low cost',
        text:
          'Not only is our system low cost but it is easy to setup and get growing.',
      },
    ],
    innovations: [
      {
        color: '#f1faf1',
        img: 'smart_home',
        title: 'HydroE',
        text:
          'Our HydroE Bluetooth controller measures conductivity, air and water temperature, and light intensity. '
          + 'It controls nutrient concentration, a water pump and water levels. It runs off 12 V which lowers cost and simplifies power requirements. '
          + 'The HydroE could be powered by solar, a standard mains adapter or even a PC power supply. '
          + 'It is an Internet of Things (IoT) device. Connect to the controller through a Bluetooth app available for any modern Android device.',
        blog: '', // 'Learn more about the HydroE controller',
        shop: '', // 'PRE-ORDER STARTER PACKAGE'
      },
      {
        color: '#f1f1fa',
        img: 'environment',
        title: 'BoostE',
        text:
          'We have developed boostE technology which allows plants to defend against '
          + 'a variety of pests, without the need of toxic horticultural sprays, so that your produce is free '
          + 'of pesticide residues. Where spraying is required we supply two organic sprays which can be used in '
          + "rotation so that the pests don't build immunity.",
        blog: '', // 'Learn more about our BoostE technology',
        shop: '', // PRE-ORDER STARTER PACKAGE'
      },
      {
        color: '#faf1f1',
        img: 'babylon',
        title: 'babylonE',
        text:
          'BabylonE gullies are easy to set up and suitable for any hothouse, shade house or can be hung from an apartment balcony. '
          + 'Gone is the need for a large nutrient tank. Tanks can be as small as a 2 gallon (7.5 L) bucket.',
        blog: '', // 'These easy to follow instructions will guide in setting up your gullies',
        shop: '', // 'PRE-ORDER GULLIES'
      },
      {
        color: '#f1f1f1',
        title: 'bufferE',
        img: 'calculator',
        text:
          'Gone is the calibration and use of fragile pH probes, '
          + 'as well as the pH control of nutrient solutions using highly corrosive acids or alkalines. '
          + 'Our BufferE technology keeps the pH of the nutrient solution within the range for '
          + 'absorption of iron and other essential trace elements.',
        shop: '', // 'PRE-ORDER NUTRIENT SOLUTION'
      },
    ],
  }),
};
</script>
