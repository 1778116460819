<template>
  <div>
    <v-app-bar
      app
      dark
      fade-img-on-scroll
      hide-on-scroll
      src="@/images/splant_mosaic.jpg"
      color="accent"
      flat
      light
      height="150"
      extension-height="80"
    >
      <template v-slot:img="{ props }">
        <v-img
          v-bind="props"
          gradient="to top, rgba(255,255,255,1.0), rgba(165,132,172,0.3)"
        ></v-img>
      </template>
      <!-- <v-app-bar-nav-icon
        @click.stop="drawer = !drawer"
        class="primary--text"
        color="accent"
      ></v-app-bar-nav-icon> -->
      <div class="d-flex align-center">
        <v-img
          alt="Logo"
          class="shrink mr-2"
          contain
          src="@/assets/ego_logo.svg"
          transition="fade-transition"
          width="40"
        />
<a href="https://elasticgreen.com">
        <v-img
          alt="ElasticGreen"
          class="shrink mt-1 hidden-sm-and-down"
          contain
          min-width="100"
          src="@/assets/ego_camel.svg"
          width="200"
        />
</a>
        <div class="ml-10"></div>
      </div>
      <template v-slot:extension>
        <v-tabs v-model="tab" align-with-title height="80" class="my-16">
          <v-tabs-slider color="primary"></v-tabs-slider>
          <v-spacer></v-spacer>
          <v-tab
            class="primary--text text--darken-3 hidden-sm-and-down"
            v-for="link in links"
            :key="link.text"
            :to="link.route"
          >
            {{ link.text }}
          </v-tab>
          <v-img
            alt="Logo"
            class="rotating shrink mx-10"
            contain
            src="@/assets/leef.svg"
            transition="fade-transition"
            width="60"
          />
        </v-tabs>
      </template>

      <v-spacer></v-spacer>

      <!-- <v-btn
        href="https://github.com/vuetifyjs/vuetify/releases/latest"
        target="_blank"
        text
      >
        <span class="mr-2">Video Tutorials</span>
        <v-icon>mdi-open-in-new</v-icon>
      </v-btn> -->

      <v-navigation-drawer
        v-model="drawer"
        fixed
        permanent
        dark
        color="primary"
        class="d-md-none"
      >
        <v-list>
          <v-list-item
            dense
            v-for="link in links"
            :key="link.text"
            :to="link.route"
          >
            <v-list-item-icon>
              <v-icon>{{ link.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="text-h6 font-weight-light">
                {{ link.text }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>
    </v-app-bar>
  </div>
</template>

<script>
export default {
  data() {
    return {
      drawer: false,
      tab: null,
      links: [
        { icon: 'mdi-home', text: 'Home', route: '/' },
        { icon: 'mdi-developer-board', text: 'HydroE', route: '/hydroe' },
        {
          icon: 'mdi-file-document-multiple',
          text: 'Documentation',
          route: '/documentation',
        },
        { icon: 'mdi-school', text: 'Resources', route: '/resources' },
        { icon: 'mdi-information-variant', text: 'About', route: '/about' },
      ],
    };
  },
};
</script>

<style lang="sass">
.rotating
  transition: transform 4s ease-in-out
.rotating:hover
  transform: rotateZ(720deg) scale(1.3)
</style>
