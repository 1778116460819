import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '@/views/Home.vue';
import Resources from '@/views/Resources.vue';
import Documentation from '@/views/Documentation.vue';
import Newsletter from '@/views/Newsletter.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/documentation',
    name: 'Documentation',
    component: Documentation,
  },
  {
    path: '/resources',
    name: 'Resources',
    component: Resources,
  },
  {
    path: '/community',
    name: 'community',
    beforeEnter: () => {
      window.location = 'http://community.elasticgreen.com';
    },
  },
  {
    path: '/hydroe',
    name: 'hydroe',
    beforeEnter: () => {
      window.location.href = 'http://hydroe.elasticgreen.com'; // http://localhost:8081'; // for development
    },
  },
  { path: '/newsletter', name: 'newsletter', component: Newsletter },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
